// External Dependencies
import PropTypes from 'prop-types';
import React from 'react';
import { graphql } from 'gatsby';

// Internal Dependencies
import Layout from '../components/layout';
import Meta from '../components/Meta';
import ReleaseNotesPreview from '../components/ReleaseNotesPreview';

// Local Variables
const propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({}).isRequired,
      ).isRequired,
    }).isRequired,
  }).isRequired,
};

// Component Definition
const Releases = ({
  data: {
    allMarkdownRemark: {
      edges: releases,
    },
  },
}) => (
  <Layout variant="releases">
    <Meta title="Releases" />

    <div
      aria-hidden="true"
      className="release-divider divider-top"
    />

    <main id="releases">

      <p className="releases-summary">
        We&apos;re <em>constantly</em> making Presto Assistant better.
        Here are many of the notable features and improvements added to Presto
        since it first launched.
      </p>

      <section className="releases-article-wrapper">
        {releases.map(({ node }) => {
          const {
            date,
            title,
          } = node.frontmatter;

          return (
            <ReleaseNotesPreview
              date={date}
              description={node.excerpt}
              key={node.fields.slug}
              slug={node.fields.slug}
              title={title}
            />
          );
        })}
      </section>
    </main>

    <div
      aria-hidden="true"
      className="release-divider divider-bottom"
    />
  </Layout>
);

Releases.propTypes = propTypes;

export default Releases;

export const pageQuery = graphql`
  query ReleasesPageQuery {
    allMarkdownRemark(
      sort: {
        fields: [frontmatter___date]
        order: DESC,
      },
      limit: 100,
      filter: {
        fields: {
          collection: {
            eq: "release"
          }
        }
      }
    ) {
      edges {
        node {
          html
          id
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM D, YYYY")
            title
          }
        }
      }
    }
  }
`;
