import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

// Local Variables
const propTypes = {
  date: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

// Component Definition
const ReleaseNotesPreview = ({
  date,
  description,
  slug,
  title,
}) => (
  <Link
    className="release-notes-preview"
    to={`/releases${slug}`}
  >
    <article className="release-preview-wrapper">
      <div className="release-preview-content">
        <header>
          <div className="release-date">{date}</div>
          <h2>
            {title}
          </h2>
        </header>

        <p className="excerpt">{description}</p>
      </div>
    </article>
  </Link>
);

ReleaseNotesPreview.propTypes = propTypes;

export default ReleaseNotesPreview;
